<template>
  <div class="top-nohu">
    <SectionTitle title="Đua top phá hũ" link="/game/no-hu" />
    <div class="top-nohu__container">
      <template v-for="(item, index) in listGameJackpotHome.slice(0, 6)" :key="index">
        <CommonGamesItemGame
          class="item-game"
          :item="item"
          :class="`${item?.class ? item?.class : ''}`"
          :is-play-now="false"
        />
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import { HOME_LIVE_CASINO_EVO, HOME_LIVE_CASINO_MG } from '~/resources/home'
import { useJackpot } from '~/composables/game/useJackpot'
import CommonGamesItemGame from '~/components/common/game-item.vue'
import { useGame } from '~~/composables/game/useGame'
import { PATH_GAME_URL } from '~/constants/path'
const SectionTitle = defineAsyncComponent(() => import('@/components/common/title-section.vue'))
const { fetchListGameJackpot, placeholderCount, listGameJackpotHome } = useGame()
const { fetchJackpot } = useJackpot()
useAsyncData(async () => {
  await fetchListGameJackpot()
  await fetchJackpot()
})
</script>
<style lang="scss" scoped src="~/assets/scss/components/mobile/pages/home/top-nohu/index.scss"></style>
